import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Modal,
    Backdrop,
    Fade,
    Grid,
    TextField,
} from "@mui/material";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import "modules/copas/style.css";
import ResultDetail from 'modules/copas/components/ResultDetail';

const ResultButton = () => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(null); // 選択状態がない場合はnull
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState(""); // 検索クエリ
    const { results } = useSelector(state => state.copas);

    const itemsPerPage = 100;


    // フィルタリングされた結果
    const filteredResults = results
        .map((result, idx) => ({ result, originalIndex: idx })) // 元のインデックスを保持
        .filter(item =>
            item.result.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const totalPages = Math.ceil(filteredResults.length / itemsPerPage);
    const paginatedResults = filteredResults.slice(page * itemsPerPage, (page + 1) * itemsPerPage);

    // 現在のインデックスが検索結果から除外された場合、nullにリセット
    useEffect(() => {
        if (index !== null && !filteredResults.some(item => item.originalIndex === index)) {
            setIndex(null);
        }
    }, [filteredResults, index]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0); // 検索時にページをリセット
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        setIndex(filteredResults[newPage * itemsPerPage]?.originalIndex || null); // ページ切り替え時に最初の結果を選択
    };

    const handleItemClick = (originalIndex) => {
        setIndex(originalIndex);
    };

    //解析結果がないときは非表示
    if (results.length === 0) return null;

    return (
        <>
            <ListItemButton onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <PlayCircleFilled />
                </ListItemIcon>
                <ListItemText primary="解析結果" />
            </ListItemButton>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal"
            >
                <Fade in={open}>
                    <div className="modal-box">
                        <div className="modal-header">
                            <h2>テキストの解析結果</h2>
                            <p>文をクリックすることで、アップロードされたテキストの解析結果を確認できます。</p>
                        </div>

                        {/* 検索バー */}
                        <div className="search-bar">
                            <TextField
                                className="search-input"
                                label="文を検索"
                                variant="outlined"
                                size="small"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>

                        <Grid container className="plfiles-index">
                            <Grid item xs={2} className="plfiles-index-list">
                                <List>
                                    {paginatedResults.map(item => (
                                        <ListItemButton
                                            key={item.originalIndex}
                                            className="plfiles-index-list-item"
                                            disabled={item.originalIndex === index}
                                            onClick={() => handleItemClick(item.originalIndex)}
                                        >
                                            <ListItemText primary={item.result} />
                                        </ListItemButton>
                                    ))}
                                </List>

                                {/* ページネーション */}
                                <div className="pagination-controls">
                                    {Array.from({ length: totalPages }, (_, p) => (
                                        <button
                                            key={p}
                                            onClick={() => handlePageChange(p)}
                                            disabled={p === page}
                                        >
                                            {p + 1}
                                        </button>
                                    ))}
                                </div>
                            </Grid>

                            <Grid item xs={10}>
                                {index !== null ? (
                                    <ResultDetail
                                        tab={tab}
                                        index={index}
                                        onTabChange={setTab}
                                    />
                                ) : (
                                    <p>解析結果が選択されていません。</p>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default ResultButton;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography, Modal, Backdrop, Fade, Button, CircularProgress, Box, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import "modules/copas/style.css";
import { djangoClient } from 'api';
import { re_convert } from 'modules/copas/store';

const PreviousResultButton = () => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [filedata, setFiledata] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const itemsPerPage = 5; 
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const { pending } = useSelector(state => state.copas);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            const fetchData = async () => {
                try {
                    const response = await djangoClient.get_file_data();
                    const sortedData = response.data.file_data.sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date));
                    setFiledata(sortedData);
                    setTotalPages(Math.ceil(sortedData.length / itemsPerPage));
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }
    }, [open]);

    if (!isAuthenticated) return null;

    const handleNextPage = () => {
        if (page < totalPages - 1) setPage(page + 1);
    };

    const handlePreviousPage = () => {
        if (page > 0) setPage(page - 1);
    };

    const handleFileClick = (file) => {
        setSelectedFile(file);
    };

    const LoadConvertResults = () => {
        dispatch(re_convert(selectedFile.file_id));
    };

    const handleDelete = async () => {
        try {
            await djangoClient.delete_file_data(selectedFile.file_id);
            setFiledata(prev => prev.filter(file => file.file_id !== selectedFile.file_id));
            setSelectedFile(null);
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error("削除に失敗しました", error);
        }
    };

    const renderFiledata = () => {
        const startIndex = page * itemsPerPage;
        const selectedData = filedata.slice(startIndex, startIndex + itemsPerPage);

        return (
            <List>
                {selectedData.map((file) => (
                    <ListItemButton 
                        key={file.file_id} 
                        onClick={() => handleFileClick(file)}
                        disabled={file.file_id === (selectedFile && selectedFile.file_id)}
                    >
                        <ListItemText
                            primary={<Typography variant="h6">{file.file_name}</Typography>}
                            secondary={<Typography variant="body2" color="textSecondary">{new Date(file.upload_date).toLocaleString()}</Typography>}
                        />
                    </ListItemButton>
                ))}
            </List>
        );
    };

    return (
        <>
            <ListItem button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="以前に解析したファイルをロード" />
            </ListItem>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className="modal"
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-box">
                        <div className="modal-header">
                            <h2 id="transition-modal-title">過去に解析したファイルの一覧を表示</h2>
                            <p id="transition-modal-description">以前解析したファイルをクリックし，ロードボタンをクリックすることでファイルの解析結果をブラウザにロードできます</p>
                        </div>
                        <div className="plfiles-index-list-item" style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: '20px' }}>
                                {filedata.length > 0 ? renderFiledata() : <div>Loading...</div>}
                            </div>
                            <div className="plfile-detail" style={{ flex: 2 }}>
                                {selectedFile && (
                                    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                        {selectedFile.file_text}
                                    </Typography>
                                )}
                            </div>
                        </div>
                        <div>
                            {selectedFile && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                                    <Button variant="contained" color="primary" onClick={LoadConvertResults}>ロード</Button>
                                    <Button variant="contained" color="error" onClick={() => setDeleteDialogOpen(true)}>削除</Button>
                                </Box>
                            )}
                        </div>
                        <div>
                            <Button onClick={handlePreviousPage} disabled={page === 0}>前のページ</Button>
                            <Button onClick={handleNextPage} disabled={page === totalPages - 1}>次のページ</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>削除確認</DialogTitle>
                <DialogContent>
                    <Typography>本当にこのファイルを削除しますか？</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">キャンセル</Button>
                    <Button onClick={handleDelete} color="error">削除</Button>
                </DialogActions>
            </Dialog>
            <div className="loading" style={{ display: pending ? 'block' : 'none' }}>
                <div className="loadingInner">
                    <p className="loadgingText">コンバート中...</p>
                    <CircularProgress style={{ visibility: pending ? 'visible' : 'hidden' }} />
                </div>
            </div>
        </>
    );
};

export default PreviousResultButton;

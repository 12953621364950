import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Box, Typography, TextField, Fade, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import "modules/auth/style.css";
import { resetMsg, signup, sendSignupCode, login } from 'modules/auth/store';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';


const SignupButton = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState(''); // メールアドレス用の状態
    const [password, setPassword] = useState(''); // パスワード用の状態
    const [verifyCode, setVerificationCode] = useState(''); // 認証コードの状態
    const [showPassword, setShowPassword] = useState(false); // パスワードの可視化状態

    const dispatch = useDispatch()

    const pending = useSelector(state => state.auth.pending);
    const isSentMail = useSelector(state => state.auth.isSentMail);
    const mailSuccessMsg = useSelector(state => state.auth.mailSuccessMsg);
    const mailErrorMsg = useSelector(state => state.auth.mailErrorMsg);
    const PasswordResetSuccessMsg = useSelector(state => state.auth.PasswordResetSuccessMsg);
    const VerifyCodeErrorMsg = useSelector(state => state.auth.VefifyCodeErrorMsg);
    const PasswordErrorMsg = useSelector(state => state.auth.PasswordErrorMsg);
    
    const isSignedup = useSelector(state => state.auth.isSignedup);

      // パスワード可視化切り替え
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const handleOpen = useCallback(() => {
        dispatch(resetMsg());
        setOpen(true);  
    }, [dispatch])
    
    const handleClose = () => {
        setOpen(false);
        setEmail('');
        setPassword('');
        setVerificationCode('');
    }
    // 認証コード送信の処理
    const handleSendEmail = (event) => {
        event.preventDefault();
        dispatch(sendSignupCode({email})); // バックエンドへ認証コードを送信
    };

    const handleSignup = (event) => {
        event.preventDefault();
        dispatch(signup({ email:email, code: verifyCode, password:password }));
        setVerificationCode('');
    };
    
    useEffect (() => {
        if(isSignedup){
            dispatch(login({ email:email, password:password}));
            setPassword('');
        }
        
    }, [dispatch, email, password, isSignedup])

    return (
        <>
        <div className="icon">
            <div className="icon_location" onClick={handleOpen}>
            <PersonAddIcon style={{ fontSize: 36 }} />
            <p  className="icon_text">サインアップ</p>
            </div>
        </div>      

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="login-modal-title"
            aria-describedby="login-modal-description"
        >
            <Fade in={open}>
            <Box className="login_modal">
                <Typography id="login-modal-title" variant="h6" component="h2">
                <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                    <h2>アカウント登録</h2>
                </div>
                <img src={"/asagao.png"} style={{ height: "60px", marginLeft: "10px" }} alt={"日本語意味役割検索システム"} />
                </div>
                </Typography>

                {!isSentMail ? (
                    <>
                        <Typography id="password-reset-modal-description" variant="body2"　>
                            <br />
                            アカウントを作成するための認証コードを送信します。
                        </Typography>
                        <form onSubmit={handleSendEmail}>
                            <TextField
                                margin="normal"
                                label="メールアドレス"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {mailErrorMsg && (
                                <Typography color="error" variant="body2">
                                    {mailErrorMsg}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                sx={{ mt: 2 }}
                                disabled={pending || !email.trim()} // 空メールの送信を防ぐ
                            >
                                {pending ? "送信中..." : "認証コードを送信"}
                            </Button>
                        </form>                 
                    

                        {pending && (  // ロード中にスピナーを表示
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                <CircularProgress />
                            </div>
                        )}
                    </>
                    ):(
                    <>   
                        <form onSubmit={handleSignup}>                      
                            <Typography color="success" variant="body2">
                                <br />                                    
                                {mailSuccessMsg}
                            </Typography>

                            <TextField
                                margin="normal"
                                label="認証コード"
                                variant="outlined"
                                fullWidth
                                value={verifyCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                            />
                            {VerifyCodeErrorMsg && (
                                <Typography color="error" variant="body2">
                                    {VerifyCodeErrorMsg}
                                </Typography>
                            )}
                            <TextField
                                margin="normal"
                                label="パスワードを設定"
                                type={showPassword ? "text" : "password"} // 可視化状態に応じて切り替え
                                variant="outlined"
                                fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            {PasswordErrorMsg && (
                                <Typography color="error" variant="body2">
                                    {PasswordErrorMsg}
                                </Typography>
                            )}

                            {PasswordResetSuccessMsg && (
                                <Typography color="success" variant="body2">
                                    {PasswordResetSuccessMsg}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disabled={pending || !verifyCode.trim() || !password.trim()} // 必須入力が揃っていないときは無効化
                                sx={{ mt: 2 }}
                            >
                            アカウントを登録
                            </Button>
                        </form>
                    </>
                    )}
                    <Button
                        variant="text"
                        color="secondary"
                        fullWidth
                        sx={{ mt: 1 }}
                        onClick={handleClose}
                        >
                        キャンセル
                    </Button>
            </Box>
            </Fade>
        </Modal>

        </>
    );
};

export default SignupButton;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Tabs,
    Tab
} from "@mui/material";
import { Graphviz } from 'graphviz-react';
import { djangoClient } from 'api';
import { memo } from 'react';  // memoで不要な再レンダリングを防止




const ResultDetail = memo(({ tab, index, onTabChange }) => {
    const { file_id } = useSelector(state => state.copas) //解析結果の識別キー
    const [results, setResults] = useState(0);

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await djangoClient.get_convert_results({
                    file_id: file_id,
                    sentence_id: index
                })
                setResults(response.data.results);
                

            } catch (error) {
                console.error(error);
            }
        };


        fetchData();
    }, [index, file_id]);


    const renderASA = () => {
        const detail = results["asa_json"];

        return <p>{JSON.stringify(detail)}</p>;
    };

    const renderProlog = () => {
        const detail = results["predicates"];
        return detail.split("\n").map((value, idx) => {
            return <p key={idx.toString()}>{value}</p>;
        })
    }

    const renderTree = () => {
        const dotString = results["dot_string"];

        let options = {
            height: "60vh",
            width: "100%",
            zoom: true,
            zoomScaleExtent: [0.1, 100],  // 最小倍率を0.1、最大倍率を100に設定
            useWorker: false,
            dpi: 300  // 解像度を上げるためのオプションを追加
        }
        return <Graphviz dot={dotString} options={options}></Graphviz>
        
    };


    const renderControl = () => {
        /*レンダリング待ち*/
        if (!results) {
            return <div>Loading...</div>;
        }

        switch (tab) {
            case 0:
                return renderASA();
            case 1:
                return renderProlog();
            case 2:
                return renderTree();
            default:
                return null;
        }
    };



    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, newTab) => onTabChange(newTab)}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={"ASA"} />
                <Tab label={"prolog"} />
                <Tab label={"prolog木構造"} />
            </Tabs>
            <div className="plfile-detail">
                {renderControl()}
            </div>
        </>
    )
});
export default ResultDetail;

// store.js
import { djangoClient } from 'api';

// アクションタイプの定義
const ActionTypes = {
    LOGIN: {
        CHECK: "LOGIN_CHECK", // ログイン処理開始
        SUCCESS: "LOGIN_SUCCESS", // ログイン成功
        FAILURE: "LOGIN_FAILURE", // ログイン失敗
    },
    AUTH: {
        CHECK: "AUTH_CHECK", // 認証処理開始
        SUCCESS: "AUTH_SUCCESS", // 認証成功
        FAILURE: "AUTH_FAILURE", // 認証失敗
    },
    LOGOUT: {
        SUCCESS: "LOGOUT_SUCCESS", // ログアウト成功
        FAILURE: "LOGOUT_FAILURE", // ログアウト失敗
    },
    MAIL: {
        CHECK: "SEND_MAIL_CHECK", // メール送信処理開始
        SUCCESS: "SEND_MAIL_SUCCESS", // メール送信成功
        FAILURE: "SEND_MAIL_FAILURE", // メール送信失敗
    },
    SIGNUP: {
        CHECK: "SIGNUP_CHECK", //サインアップ処理開始
        SUCCESS: "SIGNUP_SUCCESS", // サインアップ成功
        FAILURE: "SIGNUP_FAILURE", // サインアップ失敗
    },
    PASSRESET: {
        CHECK: "PASSRESET_CHECK", //パスワードリセット処理チェック
        SUCCESS: "PASSRESET_SUCCESS",　//パスワード変更完了
        FAILURE: "PASSRESET_FAILURE",　//パスワード変更失敗
    },
    RESET: "RESET_DATA", // データリセット
};

// ログイン処理
const login = (params) => async (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN.CHECK }); // ログインチェック開始
    try {
        const res = await djangoClient.login(params);
        sessionStorage.setItem('token', res.data.csrfToken); // トークンをセッションに保存
        dispatch({ type: ActionTypes.LOGIN.SUCCESS, payload: res.data }); // ログイン成功時
        dispatch(checkAuth()); // 認証情報を受信
    } catch (error) {
        dispatch({ type: ActionTypes.LOGIN.FAILURE, payload: error.response.data }); // ログイン失敗時
    }
};

// ログアウト処理
const logout = () => async (dispatch) => {
    try {
        const res = await djangoClient.logout();
        sessionStorage.removeItem('token'); // トークンを削除
        dispatch({ type: ActionTypes.LOGOUT.SUCCESS, payload: res.data }); // ログアウト成功時
    } catch (error) {
        sessionStorage.removeItem('token');
        dispatch({ type: ActionTypes.LOGOUT.FAILURE, payload:error.response.data }); // ログアウト失敗時        
    }
};

// 認証状態を確認する処理
const checkAuth = () => async (dispatch) => {
    dispatch({ type: ActionTypes.AUTH.CHECK }); // 認証チェック開始
    try {
        const res = await djangoClient.getUserInfo();
        dispatch({ type: ActionTypes.AUTH.SUCCESS, payload: res.data }); // 認証成功時
    } catch (error) {
        console.error('認証状態の確認に失敗しました:', error);
        dispatch({ type: ActionTypes.AUTH.FAILURE, payload: error.response.data }); // 認証失敗時
    }
};

// サインアップ認証コードをメールに送信する処理
const sendSignupCode = (params) => async (dispatch) => {
    dispatch({ type: ActionTypes.MAIL.CHECK }); // メール送信中
    try {
        const res = await djangoClient.sendSignupCode(params);
        dispatch({ type: ActionTypes.MAIL.SUCCESS, payload: res.data }); // 送信成功時
        
    } catch (error) {
        console.error('認証コードの送信に失敗しました:', error);
        dispatch({ type: ActionTypes.MAIL.FAILURE, payload: error.response.data}); // 送信失敗時
    }
};

// サインアップ処理
const signup = (params) => async (dispatch) => {
    dispatch({ type: ActionTypes.SIGNUP.CHECK }); // 認証チェック開始
    try {
        const res = await djangoClient.signup(params);
        dispatch({ type: ActionTypes.SIGNUP.SUCCESS, payload: res.data }); // サインアップ成功時
    } catch (error) {
        console.error('サインアップに失敗しました:', error);
        dispatch({ type: ActionTypes.SIGNUP.FAILURE, payload: error.response.data }); // サインアップ失敗時
    }
};

//　パスワードリセット認証コードをメールに送信する処理
const sendPasswdResetCode = (params) => async(dispatch) => {
    dispatch({ type: ActionTypes.PASSRESET.CHECK})
    try {
        const res = await djangoClient.sendPasswdResetCode(params);
        dispatch({ type: ActionTypes.MAIL.SUCCESS, payload: res.data }); // 送信成功時
        
    } catch (error) {
        console.error('認証コードの送信に失敗しました:', error);
        dispatch({ type: ActionTypes.MAIL.FAILURE, payload: error.response.data}); // 送信失敗時
    }
}

const updatePasswd = (params) => async(dispatch) => {
    dispatch({ type: ActionTypes.PASSRESET.CHECK}); // 認証チェック開始
    try {
        const res = await djangoClient.updatePasswd(params);
        dispatch({ type: ActionTypes.PASSRESET.SUCCESS, payload: res.data }); // サインアップ成功時
    } catch (error) {
        console.error('サインアップに失敗しました:', error);
        dispatch({ type: ActionTypes.PASSRESET.FAILURE, payload: error.response.data }); // サインアップ失敗時
    }
}
// メッセージをリセットする処理
const resetMsg = () => (dispatch) => {
    dispatch({ type: ActionTypes.RESET }); // メッセージの状態のリセット
};



// 初期状態
const initialState = {
    //各処理の状態管理
    pending: false, // ローディング状態
    isAuthenticated: false, // ログイン認証状態
    isSignedup: false, // サインアップ状態
    isSentMail: false, // メール送信状態
    isPasswordUpdated: false, //　パスワード更新処理
    
    //成功メッセージ
    email: null, // ユーザのメールアドレス
    loginSuccessMsg: null, //ログインメッセージ
    mailSuccessMsg: null, // メール送信成功メッセージ
    SignupSuccessSuccessMsg: null, // サインアップメッセージ
    PasswordResetSuccessMsg: null, // パスワード更新成功メッセージ

    //失敗メッセージ
    loginErrorMsg: null, //ログイン失敗メッセージ
    mailErrorMsg: null, // メール送信エラーメッセージ
    PasswordErrorMsg: null,　// パスワードエラーメッセージ
    VefifyCodeErrorMsg: null, //認証コードエラーメッセージ    
};

// リデューサー
const auth = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN.CHECK:
        case ActionTypes.AUTH.CHECK:
        case ActionTypes.MAIL.CHECK:
        case ActionTypes.SIGNUP.CHECK:
        case ActionTypes.PASSRESET.CHECK:
            return { ...state, pending: true}; // ローディング中

        case ActionTypes.LOGIN.SUCCESS:　
            return { ...state, pending: false, loginSuccessMsg: action.payload.message };　//ログインメッセージを受信

        case ActionTypes.LOGIN.FAILURE:
            return { ...state, pending: false, loginErrorMsg: action.payload.message};

        case ActionTypes.AUTH.SUCCESS:
            return { ...state, pending: false, isAuthenticated: true, email: action.payload.email};

        case ActionTypes.AUTH.FAILURE:
            return { ...state, pending: false, isAuthenticated: false, email: null};

        case ActionTypes.LOGOUT.SUCCESS:
        case ActionTypes.LOGOUT.FAILURE:
            return { ...state, pending: false, isAuthenticated: false, email: null, logoutMsg: action.payload.message };

        case ActionTypes.MAIL.SUCCESS:
            return { ...state, pending: false, isSentMail: true, mailSuccessMsg: action.payload.message }; // メール送信成功時

        case ActionTypes.MAIL.FAILURE:
            return { ...state, pending: false, isSentMail: false, mailErrorMsg: action.payload.message.email?.[0] }; // サインアップ失敗時

        case ActionTypes.SIGNUP.SUCCESS:
            return { ...state, pending: false, isSignedup: true, SignupSuccessMsg: action.payload.message }; // サインアップ成功時

        case ActionTypes.SIGNUP.FAILURE:
            return { ...state, pending: false, isSignedup: false, VefifyCodeErrorMsg: action.payload.message.code?.[0] || null, PasswordErrorMsg: action.payload.message.password?.[0] || null}; // サインアップ失敗時

        case ActionTypes.PASSRESET.SUCCESS:
            return { ...state, pending: false, isPasswordUpdated: true, PasswordResetSuccessMsg: action.payload.message} //パスワードの変更に成功

        case ActionTypes.PASSRESET.FAILURE:
            //console.log(action.payload.message)
            return { ...state, pending: false, isPasswordUpdated: false, VefifyCodeErrorMsg: action.payload.message.code?.[0] || null, PasswordErrorMsg: action.payload.message.password?.[0] || null} //パスワードの変更に失敗

        case ActionTypes.RESET:
            return { 
                ...state,
                loginSuccessMsg: null,
                mailSuccessMsg: null,
                SignupSuccessMsg: null,
                PasswordResetSuccessMsg: null,
                loginErrorMsg: null,
                mailErrorMsg: null,
                PasswordErrorMsg: null,
                VefifyCodeErrorMsg: null, 
            }; // 状態をリセット
        default:
            return state;
    }
};

export default auth;

export {
    login,
    checkAuth,
    logout,
    resetMsg,
    sendSignupCode,
    signup,
    sendPasswdResetCode,
    updatePasswd
};

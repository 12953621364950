import React from "react";
import "../css/Header.css";
import UserMenu from "../modules/auth/components/UserMenu"

const Header = () => {

    return (
      <header className="header">
        <div className="header_location">
          {/*左端は空白 */}
          <p></p> 
          {/*中央はタイトル */}
          <div className="title">
            <div>
              <h1>ASAGAO</h1>
              <p className="text-xs">日本語意味役割検索システム</p>
            </div>
            <img src={"/asagao.png"} className="asagao_size" alt={"日本語意味役割検索システム"} />
          </div>
           {/*右端はログインボタンorユーザアイコン */}
           <UserMenu/>
        </div>
      </header>
    );
  }

export default Header;

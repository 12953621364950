import React from "react";
import XmlLoadButton from "modules/workSpace/components/XmlLoadButton";
import RunButton from "modules/search/components/RunButton";
import XmlSaveButton from "modules/workSpace/components/XmlSaveButton";
import ClearButton from 'modules/workSpace/components/ClearButton';
import TextUploadButton from "modules/copas/components/TextUploadButton";
import ResetButton from "modules/copas/components/ResetButton";
import { List } from "@mui/material";
import ResultButton from "modules/copas/components/ResultButton";
import PreviousResultButton from "modules/copas/components/PreviousResultButton";
import XmlSaveDBButton from "modules/workSpace/components/XmlSaveDBButton";
import XmlLoadDBButton from "modules/workSpace/components/XmlLoadDBButton";
import "../css/ControlPanel.css";

const ControlPanel = () => {
  return (
    <section className="control-panel">
      <h3>STEP1: テキストを解析</h3>
      <List>
        <TextUploadButton />
        <PreviousResultButton />
        <ResultButton />
        <ResetButton />
      </List>
      <h3>STEP2: ブロックで検索条件を指定</h3>
      <List>
        <XmlLoadDBButton />
        <XmlSaveDBButton/>
        <XmlLoadButton />
        <XmlSaveButton />
        <ClearButton />
        
      </List>
      <h3>STEP3: 検索</h3>
      <List>
        <RunButton />
      </List>
    </section>

  )
}

export default ControlPanel;

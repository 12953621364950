import React, { useEffect } from 'react';
import Blockly from 'node-blockly/browser';

const BlockDefinition = ({
    categoryName,
    categoryColour,
    blockConfigs
}) => {
    useEffect(() => {
        const toLocaleString = () => {
            const date = new Date();
            return [
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate()
            ].join('') + date.toLocaleTimeString();
        }
        blockConfigs.forEach(blockConfig => {
            const {
                blockName,
                message,
                noBlockNameInMessage,
                args,
                extraMessage,
                extraArgs,
                previousStatement,
                nextStatement,
                colour,
                exceptionMessage
            } = blockConfig;
            
            Blockly.Blocks[blockName] = {
                init: function () {


                    let initObj = {
                        message0: `${noBlockNameInMessage ? '' : blockName}${message}`,
                        args0: args,
                        previousStatement: previousStatement,
                        nextStatement: nextStatement,
                        colour: colour
                    };
                    if (extraMessage && extraArgs) {
                        initObj["message1"] = extraMessage;
                        initObj["args1"] = extraArgs;
                    }
                    this.jsonInit(initObj);
                }
            };
            Blockly.JavaScript[blockName] = function (block) {
                let codeBase = `${exceptionMessage ? '' : blockName}${exceptionMessage || message}`; // 例) chunk(SENTENCE_ID,0,%1)
                args.forEach((arg, index) => {
                    let fieldValue = arg["type"] === 'input_statement'
                        ? Blockly.JavaScript.statementToCode(block, arg["name"])
                        : block.getFieldValue(arg["name"]); // ブロック挿入タイプ ? 内側のブロックをcodeに変換 : フィールドの値を取得
                    codeBase = codeBase.replaceAll(`%${index + 1}`, fieldValue);
                });
                const localeStr = toLocaleString().replaceAll(':', '');
                codeBase = codeBase.replaceAll('%datetime', localeStr);
                if (extraArgs) {
                    let extraCodeBase = `${extraMessage}`;
                    extraArgs.forEach((arg, index) => {
                        let fieldValue = arg["type"] === 'input_statement'
                            ? Blockly.JavaScript.statementToCode(block, arg["name"])
                            : block.getFieldValue(arg["name"]);
                        extraCodeBase = extraCodeBase.replaceAll(`%${index + 1}`, fieldValue);
                    });
                    codeBase += extraCodeBase;
                }
                return codeBase;
            };
        });
    }, [blockConfigs]);
    return (
        <category name={categoryName} colour={categoryColour}>
            {blockConfigs.map(blockConfig => (
                <block type={blockConfig.blockName} key={blockConfig.blockName} />
            ))}
        </category>
    );
};

export default BlockDefinition;
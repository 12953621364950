import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Modal, Fade, Box, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LoginButtonModal from "./LoginButtonModal";
import PasswordResetModal from "./PasswordResetModal";
import { resetMsg } from "modules/auth/store";

const LoginForm = () => {
    const [open, setOpen] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => {
        dispatch(resetMsg());
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const handleForgotPassword = () => setIsPasswordReset(true);
    const handleBackToLogin = () => setIsPasswordReset(false);

    return (
        <>
        <div className="icon">
            <div className="icon_location" onClick={handleOpen}>
                <LoginIcon style={{ fontSize: 36 }} />
                <p className="icon_text">ログイン</p>
            </div>
        </div>

        <Modal open={open} onClose={handleClose}>
            <Fade in={open}>
            <Box className="login_modal">
                <Typography id="login-modal-title" variant="h6">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                    <h1>ASAGAO</h1>
                    <p className="text-xs">日本語意味役割検索システム</p>
                    </div>
                    <img
                    src={"/asagao.png"}
                    alt={"日本語意味役割検索システム"}
                    style={{ height: "60px", marginLeft: "10px" }}
                    />
                </div>
                </Typography>

                {isPasswordReset ? (
                <PasswordResetModal onBack={handleBackToLogin} />
                ) : (
                <LoginButtonModal onForgotPassword={handleForgotPassword} onClose={handleClose} />
                )}
            </Box>
            </Fade>
        </Modal>
        </>
    );
    };

    export default LoginForm;

import React, { Component } from "react";
import { connect } from "react-redux";
import { setWorkSpace } from "modules/workSpace/store";
import { Grid } from '@mui/material';
import Blockly from "node-blockly/browser";
import "modules/workSpace/style.css";

// 関数化が面倒なのでこのまま
class WorkSpace extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        Promise.all([this.renderBlocklyArea()]).then(() => {
            this.resizeBlocklyArea();
        });
        // Blocklyの入力フィールドのフォーカスを外す処理
        document.addEventListener("mousedown", this.handleOutsideClick);
    }
    componentWillUnmount() {
        // イベントリスナーを解除
        document.removeEventListener("mousedown", this.handleOutsideClick);
    }
    handleOutsideClick = (event) => {
        const blocklyDiv = document.getElementById("blockly-div");
        const widgetDiv = Blockly.WidgetDiv.DIV; // Blocklyの入力フィールド
    
        // 現在Blocklyの入力フィールドにフォーカスがある場合は処理をスキップ
        if (widgetDiv && widgetDiv.contains(event.target)) {
            return;
        }
    
        // Blocklyワークスペース外がクリックされた場合のみ処理を実行
        if (blocklyDiv && !blocklyDiv.contains(event.target)) {
            Blockly.hideChaff(); // 入力フィールドやメニューを閉じる
            document.activeElement.blur(); // アクティブ要素のフォーカスを解除
        }
    };
    renderBlocklyArea() {
        let blocklyDiv = document.getElementById("blockly-div");
        let workSpaceRef = this.props.workSpaceState.workSpace;
        workSpaceRef = Blockly.inject(blocklyDiv, {
            toolbox: document.getElementById("toolbox"),
	    zoom:
                {controls: true,
                 wheel: true,
                 startScale: 1.0,
                 maxScale: 3,
                 minScale: 0.3,
                 scaleSpeed: 1.2,
                 pinch: true},
        });
        
        workSpaceRef.addChangeListener(() => {
            // let code = Blockly.JavaScript.workspaceToCode(workSpaceRef)
            // console.log("CHANGECODE:"+code)
            this.props.setWorkSpace(workSpaceRef)
        });
        this.props.setWorkSpace(workSpaceRef);
    }

    resizeBlocklyArea() {
        let blocklyArea = document.getElementById("blockly-area");
        let blocklyDiv = document.getElementById("blockly-div");
        let workSpace = this.props.workSpaceState.workSpace;
        let onresize = function () {
            let element = blocklyArea;
            let x = 0;
            let y = 0;
            do {
                x += element.offsetLeft;
                y += element.offsetTop;
                element = element.offsetParent;
            } while (element);
            blocklyDiv.style.left = x + "px";
            blocklyDiv.style.top = y + "px";
            Blockly.svgResize(workSpace);
        };
        window.addEventListener("resize", onresize, false);
        onresize();
        Blockly.svgResize(workSpace);
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div id="blockly-area" className="blockly-area">
                        <div id="blockly-div" className="blockly-div"></div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    workSpaceState: state.workSpaceState,
});

const mapDispatchToProps = (dispatch) => ({
    setWorkSpace: (workSpace) => dispatch(setWorkSpace(workSpace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkSpace);

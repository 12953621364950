//クエリのレシピ
//(xmlファイルをロードして使うべきだがうまくいかないため直接代入している)
const plainQuery =`<block type="query_define_4" id="i_RmR}hxyHGK?n=l*(Ax" x="-5" y="-47">
<field name="PATTERN">著者と作品</field>
<field name="EXTRACTION1">Work</field>
<field name="EXTRACTION2">WorkSloc</field>
<field name="EXTRACTION3">Auth</field>
<field name="EXTRACTION4">AuthSloc</field>
<statement name="INNER_BLOCKS">
  <block type="chunk" id="0~/!,Al@6v~GlBS/Xxi,">
    <field name="CHUNK_NODE_ID">AUTH_CHUNK_ID</field>
    <next>
      <block type="and" id="Sh+O_cCB]=tq:zmd%m%j">
        <next>
          <block type="role" id="/hjGLcS8]Ua(H)QqzL;V">
            <field name="CHUNK_NODE_ID">AUTH_CHUNK_ID</field>
            <field name="ROLE">動作主</field>
            <next>
              <block type="and" id="A_J1Y{pVHtCt0(SzCX[4">
                <next>
                  <block type="main" id=",RlM[#_YJ69:6I#I?w^L">
                    <field name="CHUNK_NODE_ID">AUTH_CHUNK_ID</field>
                    <field name="MAIN_SURFACE">Auth</field>
                    <next>
                      <block type="and" id="@N}@TMu8[OTx{]cAF\`Dv">
                        <next>
                          <block type="sloc" id="(tjfx7IO#7O}^660bAW*">
                            <field name="NODE_ID">AUTH_CHUNK_ID</field>
                            <field name="NODE_SLOC">AuthSloc</field>
                            <next>
                              <block type="and" id="NXEgV*.$3%6xp1.}~Z\`V">
                                <next>
                                  <block type="chunk" id="Tz/BJ|!w|{U5M~Q{6ong">
                                    <field name="CHUNK_NODE_ID">Work_CHUNK_ID</field>
                                    <next>
                                      <block type="and" id="xPg:%n7WkQ+pU9XT|XG3">
                                        <next>
                                          <block type="role" id="{8{|=\`cM5F0vdFN{3:BW">
                                            <field name="CHUNK_NODE_ID">Work_CHUNK_ID</field>
                                            <field name="ROLE">対象生成物</field>
                                            <next>
                                              <block type="and" id="K9aYaW|XB2\`pQ.)euJxv">
                                                <next>
                                                  <block type="main" id="~0!_q[(%]o!kZn$N7hM9">
                                                    <field name="CHUNK_NODE_ID">Work_CHUNK_ID</field>
                                                    <field name="MAIN_SURFACE">Work</field>
                                                    <next>
                                                      <block type="and" id="gW6,l}qdx{[FK|fM~lz|">
                                                        <next>
                                                          <block type="sloc" id="BcKR6Q7A^1TDx$kZ;GH-">
                                                            <field name="NODE_ID">Work_CHUNK_ID</field>
                                                            <field name="NODE_SLOC">WorkSloc</field>
                                                            <next>
                                                              <block type="and" id="HRGb?gIHWKkEu-L!tOgf">
                                                                <next>
                                                                  <block type="chunk" id="rR;9t)[AS\`QpHkW=yZMK">
                                                                    <field name="CHUNK_NODE_ID">VERB_CHUNK_ID</field>
                                                                    <next>
                                                                      <block type="and" id="(p]y?iiRNJ/[?8n+16~T">
                                                                        <next>
                                                                          <block type="semantic" id="DFCR_GZzN-*Q2fvIF!gr">
                                                                            <field name="CHUNK_NODE_ID">VERB_CHUNK_ID</field>
                                                                            <field name="SEMANTIC">生成</field>
                                                                            <next>
                                                                              <block type="and" id="?MDV~\`F-56V8+2}l_y~r">
                                                                                <next>
                                                                                  <block type="main" id="+l~rHa?}$^]OnLNOp=S#">
                                                                                    <field name="CHUNK_NODE_ID">VERB_CHUNK_ID</field>
                                                                                    <field name="MAIN_SURFACE">書く</field>
                                                                                  </block>
                                                                                </next>
                                                                              </block>
                                                                            </next>
                                                                          </block>
                                                                        </next>
                                                                      </block>
                                                                    </next>
                                                                  </block>
                                                                </next>
                                                              </block>
                                                            </next>
                                                          </block>
                                                        </next>
                                                      </block>
                                                    </next>
                                                  </block>
                                                </next>
                                              </block>
                                            </next>
                                          </block>
                                        </next>
                                      </block>
                                    </next>
                                  </block>
                                </next>
                              </block>
                            </next>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </next>
      </block>
    </next>
  </block>
</statement>
</block>
<block type="query_define_2" id="7yhDWB+N,EUZI{0[RN89" x="127" y="45">
  <field name="PATTERN">金額円</field>
  <field name="EXTRACTION1">Yen</field>
  <field name="EXTRACTION2">Yen_sloc</field>
  <statement name="INNER_BLOCKS">
    <block type="chunk" id="4(,OTydpIKHORZ-dYT9a">
      <field name="CHUNK_NODE_ID">Yen_chunk_id</field>
      <next>
        <block type="and" id="hh/[0(&#96;k(+Z,Lnr@[E;A">
          <next>
            <block type="main" id="a$%KYH7)}!X.gc2[Wai{">
              <field name="CHUNK_NODE_ID">Yen_chunk_id</field>
              <field name="MAIN_SURFACE">Yen</field>
              <next>
                <block type="and" id="c8O_|:]?2t]t(wNDmdp^">
                  <next>
                    <block type="sloc" id="T$SLoF(^5%6pholWO_Oe">
                      <field name="NODE_ID">Yen_chunk_id</field>
                      <field name="NODE_SLOC">Yen_sloc</field>
                      <next>
                        <block type="and" id="^t!HP7/UrtqC!A1~EzRY">
                          <next>
                            <block type="morph" id="j/8U1cE*|W$JGNZADi+J">
                              <field name="CHUNK_NODE_ID">Yen_chunk_id</field>
                              <field name="MORPH_NODE_ID">Yen_morph_id</field>
                              <next>
                                <block type="and" id="TPOm)0#@sGYe8lye-~Km">
                                  <next>
                                    <block type="surf" id="7M*KdJmNX52FdzTYwSC.">
                                      <field name="NODE_ID">Yen_morph_id</field>
                                      <field name="NODE_SURFACE">円</field>
                                      <next>
                                        <block type="and" id=")~5Xp5jNqq(J*??g_PKe">
                                          <next>
                                            <block type="pos" id="K4tk(vOSyfvDAcVlaCbg">
                                              <field name="MORPH_NODE_ID">Yen_morph_id</field>
                                              <field name="POS">助数詞</field>
                                              <next>
                                                <block type="and" id="g)}.kRB+r-k-f{]rwu$m">
                                                  <next>
                                                    <block type="re_match" id="Q?w:3.gV{2k+;r-Rj7;T">
                                                      <field name="REGEX">"^[1-9][0-9]{3,6}円"</field>
                                                      <field name="REGEX_TARGET">Yen</field>
                                                    </block>
                                                  </next>
                                                </block>
                                              </next>
                                            </block>
                                          </next>
                                        </block>
                                      </next>
                                    </block>
                                  </next>
                                </block>
                              </next>
                            </block>
                          </next>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </next>
    </block>
  </statement>
</block>`
;

export default plainQuery;

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ListItem, ListItemIcon, ListItemText, Modal, Box, Button, Typography, List, ListItemButton, Fade, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import LoadIcon from "@mui/icons-material/CloudDownload";
import 'modules/workSpace/style.css';
import { djangoClient } from 'api';
import Blockly from "node-blockly/browser";

const XmlLoadDBButton = () => {
    const [open, setOpen] = useState(false);
    const [queryList, setQueryList] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
     // ダイアログの状態管理
    const itemsPerPage = 10;  //1ページにつき
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const { workSpace } = useSelector(state => state.workSpaceState);

    const toggleModal = () => {
        setOpen(prev => !prev);
        setErrorMessage('');
        setSelectedQuery(null);
    };

    const fetchQueries = async () => {
        try {
            const response = await djangoClient.loadQuery();
            if (response.status === 204 || !response.data.query_data) {
                // 204の場合やquery_dataが存在しない場合は空リストを設定
                setQueryList([]);
                setTotalPages(0);
                return;
            }
            const sortedData = response.data.query_data.sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date));
            setTotalPages(Math.ceil(sortedData.length / itemsPerPage));　//取得件数からページ数を計算
            setQueryList(sortedData);
            //console.log(sortedData)
        } catch (error) {
            console.error(error);
            setErrorMessage(`データ取得失敗: ${error.message}`);
        }
    };

    useEffect(() => {
        if (open) {
            fetchQueries();
        }
    }, [open]);

    const handleQueryClick = (query) => {
        setSelectedQuery(query);
    };

    const handleNextPage = () => {
        if (page < totalPages - 1) setPage(page + 1);
    };

    const handlePreviousPage = () => {
        if (page > 0) setPage(page - 1);
    };
    
   const loadXmlData = (xmltext) => {
        let xmlDom = Blockly.Xml.textToDom(xmltext);
        workSpace.clear()
        Blockly.Xml.domToWorkspace(xmlDom, workSpace)
        setOpen(false);
    };

    const deleteXMLData = async () => {
        try {
            await djangoClient.deleteQuery(selectedQuery.query_id);
            setQueryList(prev => prev.filter(query => query.query_id !== selectedQuery.query_id));
            setSelectedQuery(null);
            setDeleteDialogOpen(false); // ダイアログを閉じる
        } catch (error) {
            console.error("削除に失敗しました", error);
        }
    };
    if (!isAuthenticated) return null;

    return (
        <>
            <ListItem button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <LoadIcon />
                </ListItemIcon>
                <ListItemText primary="検索条件をサーバーから読み込む" />
            </ListItem>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={toggleModal}
                closeAfterTransition
                className="modal"
            >
                <Fade in={open}>
                    <Box className="modal-box">
                        <h2 id="transition-modal-title"> 保存済みのクエリ一覧</h2>
                        <Typography variant="body1" id="transition-modal-description">
                            サーバー上に保存したクエリのファイル名をクリックし，ロードボタンをクリックすることでワークスペースに呼び出すことができます
                        </Typography>
                        {errorMessage && (
                            <Typography variant="body1" color="error">
                                {errorMessage}
                            </Typography>
                        )}
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={4}>
                                <List sx={{ maxHeight: 400, overflow: 'auto', borderRight: '1px solid #ddd' }}>
                                    {queryList.map((query, index) => (
                                        <ListItemButton
                                            key={index}
                                            disabled={selectedQuery === query}
                                            onClick={() => handleQueryClick(query)}
                                        >
                                            <ListItemText
                                                primary={query.file_name}
                                                secondary={new Date(query.upload_date).toLocaleString()}
                                            />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="h5">選択されたクエリ詳細</Typography>
                                {selectedQuery ? (
                                    <Box>
                                        <Typography variant="body1" >
                                            <strong>コメント:</strong> 
                                            <br />
                                            <span >
                                                {selectedQuery.user_comment || "なし"}
                                            </span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ mt: 2 }}>
                                            <strong>prologデータ:</strong>
                                        </Typography>
                                        <Box className="code-box">
                                            <Typography
                                                variant="body1"
                                                component="pre"
                                                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                                            >
                                                {selectedQuery.prolog_code || "なし"}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ mt: 2 }}
                                                onClick= {() => loadXmlData(selectedQuery.xml_data)}
                                            >
                                                ロード
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                sx={{ mt: 2 }}
                                                onClick= {() => setDeleteDialogOpen(true)}
                                            >
                                                削除
                                            </Button>
                                        </Box>
                                    </Box>
                                    
                                ) : (
                                    <Typography variant="body2">クエリを選択してください</Typography>
                                )}
                            </Grid>
                            <div>
                                <Button onClick={handlePreviousPage} disabled={page === 0}>
                                    前のページ
                                </Button>
                                <Button onClick={handleNextPage} disabled={page === totalPages - 1}>
                                    次のページ
                                </Button>
                            </div>
                            
                        </Grid>
                    </Box>
                </Fade>
            </Modal>

            
            {/* 削除確認ダイアログ */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>削除確認</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        本当にこのクエリを削除しますか？この操作は取り消せません。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={deleteXMLData} color="error">
                        削除
                    </Button>
                </DialogActions>
            </Dialog>          
        </>
    );
};

export default XmlLoadDBButton;
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { ListItemButton, ListItemIcon, ListItemText, Modal, Box, TextField, Button } from "@mui/material";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Blockly from "node-blockly/browser";
import 'modules/workSpace/style.css';
import { djangoClient } from 'api';

const XmlSaveDBButton = () => {
    const { workSpace } = useSelector(state => state.workSpaceState);
    const [open, setOpen] = useState(false);
    const [fileNameBase, setFileNameBase] = useState(''); // Base name without extension
    const [comment, setComment] = useState('');
    const [saveMessage, setSaveMessage] = useState('');
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const toggleModal = () => {
        setOpen(!open);
        setComment('');
        setFileNameBase('');
        setSaveMessage(''); // Reset the message when toggling the modal
    };
   

    const saveToServer = async () => {
        try {
            const xmlDom = Blockly.Xml.workspaceToDom(workSpace);
            const xmlText = Blockly.Xml.domToText(xmlDom);

            // Append .xml to the base file name
            const fileNameWithExtension = `${fileNameBase}.xml`;
            let code = Blockly.JavaScript.workspaceToCode(workSpace) 
            if(code){
                const response = await djangoClient.saveQuery({
                    filename: fileNameWithExtension,
                    user_comment:comment,
                    xml_data: xmlText,
                    prolog_code: code
                })
                if (response.status === 200) {
                    setSaveMessage('サーバに保存しました。');
                    await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
                    toggleModal();
                } else {         
                    alert(`サーバーエラー(${response.status})が発生しています．解決しないようであれば管理者に報告してください`);
                }
            }
            else{
                alert('ワークスペースが空です');
                return
            }
        } catch (error) {
            alert(`保存失敗: ${error.message}`);
        }
    };

    if (!isAuthenticated) return null;

    return (
        <>
            <ListItemButton onClick={toggleModal}>
                <ListItemIcon>
                    <SaveAsIcon />
                </ListItemIcon>
                <ListItemText primary="検索条件をサーバーに保存" />
            </ListItemButton>
            <div>
                <Modal open={open} onClose={toggleModal}>
                    <Box className="xml-modal">
                        <h2>クエリをサーバに保存</h2>
                        <p>現在ワークスペースに作成したクエリをファイル名とコメントを設定して保存します</p>
                        <Box display="flex" alignItems="center" gap={1}>
                            <TextField
                                label="ファイル名"
                                value={fileNameBase}
                                onChange={(e) => setFileNameBase(e.target.value.replace(/\s/g, '_'))} // Replace spaces with underscores
                                style={{ flex: 1 }} // Make the TextField smaller
                                inputProps={{ maxLength: 30 }}
                            />
                            <span>.xml</span> {/* Display the fixed extension */}
                        </Box>
                        <TextField
                            label="コメント"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            fullWidth
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 100 }}
                        />
                        {saveMessage && <p className="success-message">{saveMessage}</p>}
                        <Box display="flex" justifyContent="flex-end" gap={2}>
                            <Button onClick={toggleModal} variant="outlined">
                                キャンセル
                            </Button>
                            <Button onClick={saveToServer} variant="contained" disabled={!fileNameBase.trim()}>
                                保存
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default XmlSaveDBButton;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Typography, TextField, CircularProgress, IconButton, InputAdornment, Box } from "@mui/material";
import { sendPasswdResetCode, updatePasswd } from "modules/auth/store";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordResetModal = ({ onBack }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verifyCode, setVerificationCode] = useState("");
    const [showPassword, setShowPassword] = useState(false); // パスワードの可視化状態
    const dispatch = useDispatch();

    // Redux状態
    const pending = useSelector((state) => state.auth.pending);
    const isSentMail = useSelector((state) => state.auth.isSentMail);
    const mailSuccessMsg = useSelector((state) => state.auth.mailSuccessMsg);
    const mailErrorMsg = useSelector((state) => state.auth.mailErrorMsg);
    const verifyCodeErrorMsg = useSelector((state) => state.auth.VefifyCodeErrorMsg);
    const passwordErrorMsg = useSelector((state) => state.auth.PasswordErrorMsg);
    const passwordResetSuccessMsg = useSelector((state) => state.auth.PasswordResetSuccessMsg);

    // パスワード可視化切り替え
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    // メール送信ハンドラー
    const handleSendEmail = (event) => {
    event.preventDefault();
    if (email.trim() === "") {
        return; // 空のメールアドレスを防ぐ
    }
    dispatch(sendPasswdResetCode({ email }));
    };

    // パスワードリセットハンドラー
    const handlePasswordReset = (event) => {
    event.preventDefault();
    if (!verifyCode.trim() || !password.trim()) {
        return; // 空の入力を防ぐ
    }
    dispatch(updatePasswd({ email, code: verifyCode, password }));
    setPassword("");
    setVerificationCode("");
  };

  return (
    <Box>
      {!isSentMail ? (
        <>
          <Typography variant="body2" gutterBottom>
            パスワードを再設定するための認証コードを送信します。
          </Typography>
          <form onSubmit={handleSendEmail}>
            <TextField
                margin="normal"
                label="メールアドレス"
                variant="outlined"
                value={email}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                disabled={pending} // ロード中は入力を無効化
                required
            />
            {mailErrorMsg && (
              <Typography color="error" variant="body2" aria-live="polite">
                {mailErrorMsg}
              </Typography>
            )}
            <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={pending || !email.trim()} // 空メールの送信を防ぐ
            >
              {pending ? "送信中..." : "認証コードを送信"}
            </Button>
          </form>
          {pending && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography color="success" variant="body2" gutterBottom>
            {mailSuccessMsg}
          </Typography>
          <form onSubmit={handlePasswordReset}>
            <TextField
                margin="normal"
                label="認証コード"
                variant="outlined"
                fullWidth
                value={verifyCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
            />
            {verifyCodeErrorMsg && (
              <Typography color="error" variant="body2" aria-live="polite">
                {verifyCodeErrorMsg}
              </Typography>
            )}
            <TextField
                margin="normal"
                label="新しいパスワードを設定"
                type={showPassword ? "text" : "password"} // 可視化状態に応じて切り替え
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            {passwordErrorMsg && (
              <Typography color="error" variant="body2" aria-live="polite">
                {passwordErrorMsg}
              </Typography>
            )}
            {passwordResetSuccessMsg && (
              <Typography color="success" variant="body2" aria-live="polite">
                {passwordResetSuccessMsg}
              </Typography>
            )}


            <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={pending || !verifyCode.trim() || !password.trim()} // 必須入力が揃っていないときは無効化
                required
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </InputAdornment>
                ),
                }}
            >
              パスワードを更新する
            </Button>
          </form>
        </>
      )}
        <Button
            variant="text"
            color="secondary"
            fullWidth
            sx={{ mt: 1 }}
            onClick={onBack}
        >
        戻る
        </Button>
    </Box>
  );
};

export default PasswordResetModal;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout, checkAuth } from "modules/auth/store";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginForm from "./LoginForm";
import SignupButton from "./SignupButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import "modules/auth/style.css";

const UserMenu = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleComingSoon = (feature) => {
        setDialogMessage(`${feature} 機能は現在準備中です。`);
        setDialogOpen(true); // ダイアログを開く
    };

    const closeDialog = () => {
        setDialogOpen(false); // ダイアログを閉じる
    };

    useEffect(() => {
        dispatch(checkAuth());
    }, [dispatch]);

    return (
        <>
            {isAuthenticated ? (
                <div className="icon">
                    <div
                        className="icon_location"
                        onClick={() => handleComingSoon("マイページ")}
                    >
                        <PersonIcon style={{ fontSize: 36 }} />
                        <p className="icon_text">マイページ</p>
                    </div>
                    <div className="icon_location">
                        <MenuIcon style={{ fontSize: 36 }} />
                        <p className="icon_text">メニュー</p>
                        {/*公式マニュアルとかのリンクがができれば<a href="">で遷移するが現在未実装*/}
                        <div className="dropdown-menu">
                            <span
                                className="dropdown-item"
                                onClick={() => handleComingSoon("マニュアル")}
                            >
                                マニュアル
                            </span>
                            <span
                                className="dropdown-item"
                                onClick={() => handleComingSoon("FAQ")}
                            >
                                FAQ
                            </span>
                            <span
                                className="dropdown-item"
                                onClick={() => handleComingSoon("お問い合わせ")}
                            >
                                お問い合わせ
                            </span>
                        </div>
                    </div>

                    <div className="icon_location" onClick={handleLogout}>
                        <LogoutIcon style={{ fontSize: 36 }} />
                        <p className="icon_text">ログアウト</p>
                    </div>
                </div>
            ) : (
                <div className="icon">
                    <SignupButton />
                    <div className="icon_location">
                        <MenuIcon style={{ fontSize: 36 }} />
                        <p className="icon_text">メニュー</p>
                        <div className="dropdown-menu">
                            <span
                                className="dropdown-item"
                                onClick={() => handleComingSoon("FAQ")}
                            >
                                FAQ
                            </span>
                            <span
                                className="dropdown-item"
                                onClick={() => handleComingSoon("マニュアル")}
                            >
                                マニュアル
                            </span>
                            <span
                                className="dropdown-item"
                                onClick={() => handleComingSoon("お問い合わせ")}
                            >
                                お問い合わせ
                            </span>
                        </div>
                    </div>
                    <LoginForm />
                </div>
            )}
            {/* ダイアログ */}
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>お知らせ</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserMenu;

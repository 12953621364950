import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Typography, InputAdornment, IconButton, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "modules/auth/store";

const LoginButtonModal = ({ onForgotPassword, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // パスワードの可視化状態

  const dispatch = useDispatch();
  const loginErrorMsg = useSelector((state) => state.auth.loginErrorMsg);
  const loginSuccessMsg = useSelector((state) => state.auth.loginSuccessMsg);
  const pending = useSelector((state) => state.auth.pending);

  // パスワード可視化切り替え
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <Box>
      <form onSubmit={handleLogin}>
        <TextField
          margin="normal"
          label="メールアドレス"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          label="パスワード"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {loginErrorMsg && (
          <Typography color="error" variant="body2">
            {loginErrorMsg}
          </Typography>
        )}
        {loginSuccessMsg && (
          <Typography color="success" variant="body2">
            {loginSuccessMsg}
          </Typography>
        )}
        <Typography
          variant="body2"
          color="primary"
          style={{ cursor: "pointer", marginBottom: "10px" }}
          onClick={onForgotPassword}
        >
          パスワードを忘れた場合
        </Typography>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          disabled={pending || !email.trim() || !password.trim()} // 必須入力が揃っていないときは無効化
          fullWidth sx={{ mt: 2 }}>
          ログイン
        </Button>
        <Button variant="text" color="secondary" fullWidth sx={{ mt: 1 }} onClick={onClose}>
          キャンセル
        </Button>
      </form>
    </Box>
  );
};

export default LoginButtonModal;
